<template>
  <router-view v-if="userData"/>
</template>

<script>

import { tokenCheck } from '@/libs/auth-helper'
import store from '@/store'

export default {
  name: 'TheMember',
  async beforeRouteUpdate (to, from, next) {
    this.emitter.emit('isSlot')
    await store.dispatch('storeUserData')
    const result = await tokenCheck()
    if (result) {
      next()
    } else {
      this.onCheck('front.error.afterSignin')
      location.href = '/'
    }
  }
}
</script>

<style>

</style>
